import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { editProfile, getUser } from '../services/auth';
import { countries } from '../utils/helpers';
import Toast from './Toast';
import ModalHeader from './ModalHeader';

const EditProfileModal = ({ onClose, onSuccess }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    country: '',
    phone: '',
  });
  const [password, setPassword] = useState('');
  const [toast, setToast] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        setIsLoading(true);
        const user = await getUser(true); // true to fetch from DB
        
        if (user) {
          let phoneCountryCode = '';
          let localPhoneNumber = '';
          
          if (user.phone) {
            const phoneStr = user.phone.toString();
            // Find matching country code
            for (const [countryName, countryData] of Object.entries(countries)) {
              const code = countryData.phoneCountryCode.toString();
              if (phoneStr.startsWith(code)) {
                phoneCountryCode = code;
                localPhoneNumber = phoneStr.slice(code.length);
                if (!user.country) {
                  user.country = countryName;
                }
                break;
              }
            }
          }

          setFormData({
            fullname: user.fullname || '',
            email: user.email || '',
            country: user.country || '',
            phone: localPhoneNumber || ''
          });
        }
      } catch (error) {
        showToast(t('Error loading user data'), 'error');
      } finally {
        setIsLoading(false);
      }
    };

    loadUserData();
  }, [t]);

  const showToast = (message, type = 'error') => {
    setToast({ message, type });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      // Only allow numbers
      const numbers = value.replace(/\D/g, '');
      setFormData(prev => ({ ...prev, [name]: numbers }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const phoneCountryCode = countries[formData.country]?.phoneCountryCode || '';
      const fullPhoneNumber = phoneCountryCode + formData.phone;

      const updatedUser = await editProfile({
        ...formData,
        phone: fullPhoneNumber,
        password: password || undefined
      });

      showToast(t('Profile updated successfully'), 'success');
      setTimeout(() => {
        onSuccess();
      }, 1000);
    } catch (error) {
      console.error('Error updating profile:', error);
      showToast(t('Failed to update profile'));
    }
  };

  const getPhoneCountryCode = () => {
    return formData.country ? countries[formData.country]?.phoneCountryCode || '' : '';
  };

  if (isLoading) {
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <ModalHeader title={t('Loading...')} onClose={onClose} />
          <div className="modal-body">
            <p>{t('Loading...')}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <ModalHeader title={t('Edit Profile')} onClose={onClose} />
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="fullname"
              value={formData.fullname}
              onChange={handleChange}
              placeholder={t('Full Name')}
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder={t('Email')}
              required
            />
            <select
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
            >
              <option value="">{t('Select Country')}</option>
              {Object.keys(countries).map(countryName => (
                <option key={countryName} value={countryName}>
                  {t(countryName)}
                </option>
              ))}
            </select>
            <div className="phone-input">
              <input
                type="text"
                value={getPhoneCountryCode()}
                readOnly
                className="country-code"
              />
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder={t('Phone Number')}
                required
              />
            </div>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('New Password (leave blank to keep current)')}
            />
            <button type="submit" className="submit-button">{t('Save')}</button>
          </form>
          
          {toast && (
            <Toast
              message={toast.message}
              type={toast.type}
              onClose={() => setToast(null)}
              duration={5000}
              showOverlay={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditProfileModal;