import { BACKEND_URL, API_URL, 
          FRONTEND_URL, 
          house_image, land_image, 
          apartment_image,
          DEFAULT_CARD_COUNT } from '../utils/helpers';


const getImageUrl = (imagePath, propertyType) => {
  if (!imagePath) {
    let defaultImage = house_image; // Use let instead of var
    
    switch (propertyType) {
      case 'Apartment':
        defaultImage = apartment_image;
        break;
      case 'Land':
        defaultImage = land_image;
        break;
      // You can add more cases for other property types
      default:
        defaultImage = house_image;
    }
    return defaultImage;
  }
  
  // Rest of the function...
  if (imagePath.startsWith('http')) {
    return imagePath;
  }
  
  const cleanPath = imagePath.replace(/^\/+/, '');
  return `${BACKEND_URL}${cleanPath}`;
};


export const getListings = async (params = {}) => {
  try {
    // Handle cityCountry parameter
    if (params.cityCountry) {
      const [city, country] = params.cityCountry.split(', ');
      params.city = city;
      params.country = country;
      delete params.cityCountry;
    }

    // Add pagination parameters
    const page = parseInt(params.page) || 1;
    const limit = parseInt(params.limit) || DEFAULT_CARD_COUNT; // Changed from 100 to 20
    params.offset = (page - 1) * limit;
    params.limit = limit;

    // Remove page from query params as we use offset/limit
    delete params.page;

    const queryString = new URLSearchParams(params).toString();
    const response = await fetch(`${API_URL}/properties?${queryString}`);
    if (!response.ok) {
      throw new Error('Failed to fetch listings');
    }
    const data = await response.json();
    
    // Check if data matches expected structure, if not, handle legacy format
    const listings = Array.isArray(data) ? data : (data.listings || []);
    const total = data.total || listings.length;
    
    return {
      listings: listings.map(listing => ({
        ...listing,
        imageUrl: getImageUrl(listing.image_url, listing.property_type),
        zipCode: listing.zip_code,
        sqft: listing.sqft,
        lat: parseFloat(listing.latitude),
        lng: parseFloat(listing.longitude),
        postType: listing.post_type,
        propertyType: listing.property_type,
        posterName: listing.fullname,
        cityCountry: `${listing.city}, ${listing.country}`
      })),
      total,
      currentPage: page,
      totalPages: Math.ceil(total / limit),
      limit
    };
  } catch (error) {
    console.error('Error fetching listings:', error);
    // Return empty results instead of throwing
    return {
      listings: [],
      total: 0,
      currentPage: 1,
      totalPages: 1,
      limit: params.limit || DEFAULT_CARD_COUNT // Added limit to error response
    };
  }
};

export const getListing = async (id) => {
  try {
    const response = await fetch(`${API_URL}/properties/${id}`);
    if (!response.ok) {
      throw new Error('Failed to fetch listing');
    }
    const listing = await response.json();

    const propType = listing.property_type;

    // Process images to ensure proper URLs
    const processedImages = (listing.images || []).map(imagePath => {
      // Remove any existing backend URL prefix
      const cleanPath = imagePath.replace(new RegExp(`^${FRONTEND_URL}`), '');
      return getImageUrl(cleanPath, propType);
    });

    return {
      ...listing,
      imageUrl: getImageUrl(listing.image_url, propType),
      zipCode: listing.zip_code,
      sqft: listing.sqft,
      lat: parseFloat(listing.latitude),
      lng: parseFloat(listing.longitude),
      postType: listing.post_type,
      propertyType: listing.property_type,
      posterName: listing.fullname,
      posterEmail: listing.email,
      posterPhone: listing.phone,
      images: processedImages,
      cityCountry: `${listing.city}, ${listing.country}`
    };
  } catch (error) {
    console.error('Error fetching listing:', error);
    return null;
  }
};

export const createListing = async (listingData, token) => {
  try {
    const formData = new FormData();
    
    // Handle cityCountry field
    if (listingData.cityCountry) {
      const [city, country] = listingData.cityCountry.split(', ');
      listingData.city = city;
      listingData.country = country;
      delete listingData.cityCountry;
    }

    for (const key in listingData) {
      if (key === 'images') {
        listingData[key].forEach((image, index) => {
          formData.append('images', image, `image${index}`);
        });
      } else {
        formData.append(key, listingData[key]);
      }
    }

    const response = await fetch(`${API_URL}/properties`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error('Failed to create listing');
    }

    return response.json();
  } catch (error) {
    console.error('Error creating listing:', error);
    throw error;
  }
};

export const updateListing = async (id, formData, token) => {
  try {
    const response = await fetch(`${API_URL}/properties/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData
    });

    if (!response.ok) {
      // Log the response for debugging
      const errorText = await response.text();
      console.error('Update failed:', errorText);
      throw new Error('Failed to update listing');
    }

    return response.json();
  } catch (error) {
    console.error('Error updating listing:', error);
    throw error;
  }
};

export const deleteListing = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/properties/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to delete listing');
    }

    return await response.json();
  } catch (error) {
    console.error('Error deleting listing:', error);
    throw error;
  }
};