import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Search from './pages/Search';
import About from './pages/About';
import Contacts from './pages/Contacts';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { isAuthenticated } from './services/auth';
import MetaTags from './components/MetaTags';
import './styles.css';

function App() {
  const { i18n } = useTranslation();
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);

  useEffect(() => {
    setIsUserSignedIn(isAuthenticated());
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <BrowserRouter>
      <div className="App">
        <MetaTags /> {/* This will use the default values */}
        <Header 
          changeLanguage={changeLanguage} 
          isUserSignedIn={isUserSignedIn}
          setIsUserSignedIn={setIsUserSignedIn}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<Search />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contacts />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;