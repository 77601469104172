import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { countries } from '../utils/helpers';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronLeft, ChevronRight } from 'lucide-react';

const CustomArrow = ({ className, style, onClick, icon: Icon }) => (
  <div
    className={`${className} custom-arrow`}
    style={{ ...style, display: "block", background: "rgba(0,0,0,0.3)" }}
    onClick={onClick}
  >
    <Icon size={40} color="white" />
  </div>
);

const Home = () => {
  const { t } = useTranslation();
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '60px',
    prevArrow: <CustomArrow icon={ChevronLeft} />,
    nextArrow: <CustomArrow icon={ChevronRight} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: '40px',
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '40px',
        }
      }
    ]
  };

  return (
    <div className="static-page home-page">
      <div className="home-hero">
        <h1>{t('Welcome to Habittatt')}</h1>
        <p>{t('Find your dream home or perfect rental property')}</p>
      </div>
      <div className="static-page-content-wrapper">
        <div className="card-container">
          <Slider {...settings}>
            {Object.entries(countries).map(([country, data]) => (
              <div key={country} className="card-wrapper">
                <div className="card">
                  <div className="card-image">
                    <img src={data.picture} alt={country} />
                  </div>
                  <div className="card-content">
                    <h2 className="card-title">{t(country)}</h2>
                    <p className="card-text">
                      {t('Explore beautiful homes and properties available in {{country}}', { country: t(country) })}
                    </p>
                    <div className="cta-button-container">
                      {Object.keys(data.cities).map(city => (
                        <Link
                          key={city}
                          to={`/search?cityCountry=${encodeURIComponent(`${city}, ${country}`)}`}
                          className="cta-button"
                        >
                          {t('Explore')} {t(city)}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Home;