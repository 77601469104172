// src/services/auth.js
import { API_URL } from '../utils/helpers';

export const login = async (email, password) => {
  try {
    const response = await fetch(`${API_URL}/signin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      throw new Error('Invalid email or password');
    }

    const data = await response.json();
    
    // Store user data with consistent ID type
    const userData = {
      id: String(data.userId), // Convert to string for consistency
      fullname: data.fullname,
      // Include other user data as needed
    };

    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(userData));
    
    return data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};


export const signup = (fullname, email, password, country, phone) => {
  return fetch(`${API_URL}/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ fullname, email, password, country, phone }),
  })
    .then(response => {
      if (!response.ok) {
        if (response.status === 400) {
          throw new Error('Email already in use');
        }
        throw new Error('Error during sign up');
      }
      return response.json();
    })
    .then(() => {
      return login(email, password);
    });
};


export const editProfile = async (userData) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${API_URL}/users/profile`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(userData)
    });

    if (!response.ok) {
      throw new Error('Failed to update profile');
    }

    const updatedUser = await response.json();
    localStorage.setItem('user', JSON.stringify(updatedUser));
    return updatedUser;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};


export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};

export const isAuthenticated = () => {
  return localStorage.getItem('token') !== null;
};

export const getAuthToken = () => {
  return localStorage.getItem('token');
};

export const getUserDB = async () => {
  try {
    const token = getAuthToken();
    if (!token) return null;
    
    const response = await fetch(`${API_URL}/users/profile`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Token expired or invalid
        logout(); // Clear local storage
        return null;
      }
      throw new Error('Failed to fetch user data');
    }

    const userData = await response.json();
    
    // Update localStorage with the fresh data
    localStorage.setItem('user', JSON.stringify(userData));
    
    return userData;
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
};

// Update the getUser function to optionally fetch from DB
export const getUser = () => {
  const userString = localStorage.getItem('user');
  if (!userString) return null;
  
  try {
    const user = JSON.parse(userString);
    return user;
  } catch (error) {
    console.error('Error parsing user data:', error);
    return null;
  }
};