import React from 'react';
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const getPageNumbers = () => {
      const pages = [];
      if (totalPages <= 3) {
        // If 3 or fewer pages, show all
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        // Always show first page
        pages.push(1);
        
        if (currentPage <= 2) {
          // Near the start
          pages.push(2);
          pages.push(3);
          if (totalPages > 3) pages.push('...');
        } else if (currentPage >= totalPages - 1) {
          // Near the end
          if (totalPages > 3) pages.push('...');
          pages.push(totalPages - 2);
          pages.push(totalPages - 1);
        } else {
          // In the middle
          pages.push('...');
          pages.push(currentPage);
          pages.push('...');
        }
        
        // Always show last page
        if (totalPages > 3) pages.push(totalPages);
      }
      return pages;
    };
  
    return (
      <div className="pagination">
        <button 
          onClick={() => onPageChange(1)} 
          disabled={currentPage === 1}
          className="pagination-button"
          aria-label="First page"
        >
          <ChevronsLeft size={16} /> {/* Reduced icon size */}
        </button>
        <button 
          onClick={() => onPageChange(currentPage - 1)} 
          disabled={currentPage === 1}
          className="pagination-button"
          aria-label="Previous page"
        >
          <ChevronLeft size={16} /> {/* Reduced icon size */}
        </button>
        
        {getPageNumbers().map((page, index) => (
          <button
            key={index}
            onClick={() => typeof page === 'number' ? onPageChange(page) : null}
            className={`pagination-button ${currentPage === page ? 'active' : ''} ${typeof page !== 'number' ? 'dots' : ''}`}
            disabled={typeof page !== 'number'}
          >
            {page}
          </button>
        ))}
        
        <button 
          onClick={() => onPageChange(currentPage + 1)} 
          disabled={currentPage === totalPages}
          className="pagination-button"
          aria-label="Next page"
        >
          <ChevronRight size={16} /> {/* Reduced icon size */}
        </button>
        <button 
          onClick={() => onPageChange(totalPages)} 
          disabled={currentPage === totalPages}
          className="pagination-button"
          aria-label="Last page"
        >
          <ChevronsRight size={16} /> {/* Reduced icon size */}
        </button>
      </div>
    );
  };

export default Pagination;