import React from 'react';
import { Helmet } from 'react-helmet';
import { FRONTEND_URL } from '../utils/helpers';

const MetaTags = ({ 
  title, 
  description, 
  image, 
  url,
  type = 'website',
  siteName = 'Habittatt',
  twitterCard = 'summary_large_image',
  locale = 'en_US'
}) => {
  // Ensure all values are strings and handle undefined values
  const finalTitle = String(title ? `${title} | Habittatt` : 'Habittatt - Find Your Dream Home in Africa');
  const finalDescription = String(description || 'Discover the perfect property in Africa. Browse houses, apartments, and land for sale or rent.');
  const finalImage = String(image || `${FRONTEND_URL}/images/default-share.jpg`);
  const finalUrl = String(url || FRONTEND_URL);
  const finalType = String(type);
  const finalSiteName = String(siteName);
  const finalLocale = String(locale);

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{finalTitle}</title>
      <meta name="description" content={finalDescription} />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={finalTitle} />
      <meta property="og:description" content={finalDescription} />
      <meta property="og:image" content={finalImage} />
      <meta property="og:url" content={finalUrl} />
      <meta property="og:type" content={finalType} />
      <meta property="og:site_name" content={finalSiteName} />
      <meta property="og:locale" content={finalLocale} />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={finalTitle} />
      <meta name="twitter:description" content={finalDescription} />
      <meta name="twitter:image" content={finalImage} />
    </Helmet>
  );
};

export default MetaTags;