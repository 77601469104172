import React from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="static-page">
      <div className="static-page-hero">
        <h1>{t('How to Contact Us')}</h1>
      </div>
      <div className="static-page-content-wrapper">
        <div className="static-page-card">
          <p>{t('contact.intro', "We're here to assist you with any questions or inquiries about our real estate services. Our dedicated team is ready to help you find your perfect property or address any concerns.")}</p>
          
          <h2>{t('Contact Information')}</h2>
          <div className="contact-details">
            <p><strong>{t('Website')}:</strong> <a href="https://arlinaconsulting.com" target="_blank" rel="noopener noreferrer">arlinaconsulting.com</a></p>
            <p><strong>{t('Email')}:</strong> <a href="mailto:info@arlinaconsulting.com">info@arlinaconsulting.com</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;