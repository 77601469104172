import React from 'react';
import { useTranslation } from 'react-i18next';

const TermsOfService = () => {
  const { t } = useTranslation();

  return (
    <div className="static-page">
      <div className="static-page-hero">
        <h1>{t('Terms of Service')}</h1>
      </div>
      <div className="static-page-content-wrapper">
        <div className="static-page-card">
          <p>{t('terms.intro', 'Welcome to Habittatt. By using our services, you agree to comply with and be bound by the following terms and conditions:')}</p>
          
          <h2>{t('terms.acceptance.title', '1. Acceptance of Terms')}</h2>
          <p>{t('terms.acceptance.description', 'By accessing or using the Habittatt platform, you agree to be bound by these Terms of Service and all applicable laws and regulations.')}</p>
          
          <h2>{t('terms.service.title', '2. Use of Service')}</h2>
          <p>{t('terms.service.description', 'You agree to use our service for lawful purposes only and in a way that does not infringe upon the rights of others.')}</p>
          
          <h2>{t('terms.accounts.title', '3. User Accounts')}</h2>
          <p>{t('terms.accounts.description', 'You are responsible for maintaining the confidentiality of your account and password.')}</p>
          
          <h2>{t('terms.listings.title', '4. Property Listings')}</h2>
          <ul>
            <li>{t('terms.listings.accuracy', 'All property information must be accurate and current')}</li>
            <li>{t('terms.listings.ownership', 'You must have the right to list the property')}</li>
            <li>{t('terms.listings.images', 'Images must be actual photos of the property')}</li>
          </ul>
          
          <h2>{t('terms.payments.title', '5. Payments and Fees')}</h2>
          <p>{t('terms.payments.description', 'Details about our fee structure and payment terms.')}</p>
          
          <h2>{t('terms.liability.title', '6. Limitation of Liability')}</h2>
          <p>{t('terms.liability.description', 'Habittatt shall not be liable for any indirect, incidental, special, consequential or punitive damages.')}</p>
          
          <h2>{t('terms.changes.title', '7. Changes to Terms')}</h2>
          <p>{t('terms.changes.description', 'We reserve the right to modify these terms at any time. Your continued use of Habittatt indicates acceptance of changes.')}</p>
          
          <h2>{t('terms.contact.title', '8. Contact Information')}</h2>
          <p>{t('terms.contact.description', 'For questions about these Terms, please contact us at:')} <a href="mailto:info@arlinaconsulting.com">info@arlinaconsulting.com</a></p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;