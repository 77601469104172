import React from 'react';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const ModalHeader = ({ title, onClose }) => {
  const { t } = useTranslation();
  
  return (
    <div className="modal-header">
      <h2 className="modal-title">
        {title}
      </h2>
      <button 
        className="modal-close-icon"
        onClick={onClose}
        aria-label={t('Close')}
      >
        <X size={18} /> {/* Slightly smaller icon */}
      </button>
    </div>
  );
};

export default ModalHeader;