import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="static-page">
      <div className="static-page-hero">
        <h1>{t('Privacy Policy')}</h1>
      </div>
      <div className="static-page-content-wrapper">
        <div className="static-page-card">
          <p>{t('privacy.intro', 'At Habittatt, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information.')}</p>
          
          <h2>{t('privacy.collection.title', '1. Information We Collect')}</h2>
          <p>{t('privacy.collection.description', 'We collect information that you provide directly to us, including:')}</p>
          <ul>
            <li>{t('privacy.collection.info.personal', 'Name and contact information')}</li>
            <li>{t('privacy.collection.info.login', 'Login credentials')}</li>
            <li>{t('privacy.collection.info.property', 'Property details and images')}</li>
            <li>{t('privacy.collection.info.payment', 'Payment information')}</li>
          </ul>
          
          <h2>{t('privacy.use.title', '2. How We Use Your Information')}</h2>
          <p>{t('privacy.use.description', 'We use the collected information to:')}</p>
          <ul>
            <li>{t('privacy.use.purpose.service', 'Provide and improve our services')}</li>
            <li>{t('privacy.use.purpose.transactions', 'Process transactions')}</li>
            <li>{t('privacy.use.purpose.communication', 'Communicate with you')}</li>
            <li>{t('privacy.use.purpose.experience', 'Personalize your experience')}</li>
          </ul>
          
          <h2>{t('privacy.sharing.title', '3. Information Sharing')}</h2>
          <p>{t('privacy.sharing.description', 'We may share your information with:')}</p>
          <ul>
            <li>{t('privacy.sharing.with.providers', 'Service providers')}</li>
            <li>{t('privacy.sharing.with.users', 'Other users for transactions')}</li>
            <li>{t('privacy.sharing.with.legal', 'Legal authorities when required')}</li>
          </ul>
          
          <h2>{t('privacy.contact.title', '4. Contact Us')}</h2>
          <p>{t('privacy.contact.description', 'For privacy-related questions, please contact us at:')} <a href="mailto:info@arlinaconsulting.com">info@arlinaconsulting.com</a></p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;