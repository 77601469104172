import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { updateListing } from '../services/api';
import { getAuthToken } from '../services/auth';
import { 
    getCoordinates, 
    getCityCountryOptions, 
    getCityCountry,
    calculateTotalPrice, 
    calculatePricePerUnit 
} from '../utils/helpers';
import ModalHeader from './ModalHeader';
import Toast from './Toast';
import { FRONTEND_URL } from '../utils/helpers';


const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

const splitCityCountry = (cityCountry) => {
  const [city, country] = cityCountry.split(', ');
  return { city, country };
};

const EditPostModal = ({ listing, onClose, onSuccess }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    price: '',
    bedrooms: '',
    bathrooms: '',
    sqft: '',
    address: '',
    cityCountry: '',
    state: '',
    zipCode: '',
    neighborhood: '',
    latitude: '',
    longitude: '',
    postType: '',
    propertyType: '',
  });
  const [images, setImages] = useState([]);
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [filteredNeighborhoods, setFilteredNeighborhoods] = useState([]);
  const [showNeighborhoodSuggestions, setShowNeighborhoodSuggestions] = useState(false);
  const [toast, setToast] = useState(null);  // Added toast state
  const [isPricePerUnit, setIsPricePerUnit] = useState(false);
  const [pricePerUnit, setPricePerUnit] = useState('');
  const { t, i18n } = useTranslation();
  
  // Add this helper function at the top of EditPostModal.jsx
    const cleanImagePath = (url) => {
        if (!url) return '';
        
        // Find the index of 'uploads/'
        const uploadsIndex = url.indexOf('uploads/');
        if (uploadsIndex === -1) return url; // If 'uploads/' is not found, return original url
        
        // Return everything from 'uploads/' onwards
        return url.substring(uploadsIndex);
    };

  const handleNeighborhoodFocus = () => {
    setShowNeighborhoodSuggestions(true);
  };

  const handleNeighborhoodBlur = () => {
    // Use setTimeout to allow clicking on suggestions before hiding them
    setTimeout(() => setShowNeighborhoodSuggestions(false), 200);
  };

  useEffect(() => {
    if (listing) {
        // Calculate initial price per unit if sqft exists
        let initialPricePerUnit = '';
        if (listing.sqft && listing.price) {
            initialPricePerUnit = calculatePricePerUnit(
            listing.price,
            listing.sqft,
            true,
            i18n.language
            ).toString();
        }

        setFormData({
            title: listing.title || '',
            description: listing.description || '',
            price: listing.price?.toString() || '',
            bedrooms: listing.bedrooms?.toString() || '',
            bathrooms: listing.bathrooms?.toString() || '',
            sqft: listing.sqft?.toString() || '',
            address: listing.address || '',
            cityCountry: `${listing.city}, ${listing.country}` || '',
            state: listing.state || '',
            zipCode: listing.zipCode || '',
            neighborhood: listing.neighborhood || '',
            latitude: listing.latitude?.toString() || '',
            longitude: listing.longitude?.toString() || '',
            postType: listing.postType || '',
            propertyType: listing.propertyType || '',
        });

        setPricePerUnit(initialPricePerUnit);


        // Clean and process existing images
        const existingImages = listing.images || [];
        setImages(existingImages.map(imagePath => {
        // Remove any existing backend URL prefix
        const cleanPath = imagePath.replace(new RegExp(`^${FRONTEND_URL}`), '');
        return {
            existing: true,
            path: cleanPath, // Store clean path without backend URL
            preview: imagePath // Use full URL for preview
        };
        }));
        
        fetchNeighborhoods(listing.country, listing.city);
        }
    }, [listing]);



  const fetchNeighborhoods = async (country, city) => {
    try {
      const response = await fetch(`/data/neighborhoods/${country.toLowerCase()}/${city.toLowerCase()}.json`);
      const data = await response.json();
      setNeighborhoods(Object.keys(data));
      setFilteredNeighborhoods(Object.keys(data));
    } catch (error) {
      console.error('Error fetching neighborhoods:', error);
      setNeighborhoods([]);
      setFilteredNeighborhoods([]);
    }
  };

  // Add handlePriceToggle function
  const handlePriceToggle = (e) => {
        const newIsPricePerUnit = e.target.checked;
        setIsPricePerUnit(newIsPricePerUnit);
        
        if (formData.sqft && formData.price) {
        if (newIsPricePerUnit) {
            // Calculate price per unit from current total price
            const newPricePerUnit = calculatePricePerUnit(
            formData.price,
            formData.sqft,
            true,
            i18n.language
            );
            setPricePerUnit(newPricePerUnit.toString());
        } else {
            // Keep the total price as is
            setPricePerUnit('');
        }
        }
    };

  const setDefaultCoordinates = (city, country) => {
    const coordinates = getCoordinates(country, city);
    setFormData(prev => ({
      ...prev,
      latitude: coordinates.lat,
      longitude: coordinates.lng
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (['price', 'bedrooms', 'bathrooms', 'sqft'].includes(name)) {
      // If empty value, set to null
      if (value === '') {
        if (name === 'price' && isPricePerUnit) {
          setPricePerUnit('');
        }
        setFormData(prev => ({ ...prev, [name]: null }));
        return;
      }
  
      // Convert to number and validate
      const numValue = Number(value);
      if (isNaN(numValue) || numValue < 0) {
        return; // Ignore invalid values
      }
  
      // Special handling for sqft when using price per unit
      if (name === 'sqft') {
        if (isPricePerUnit && pricePerUnit) {
          // Recalculate total price based on new size
          const totalPrice = calculateTotalPrice(
            pricePerUnit,
            numValue,
            true,
            i18n.language
          );
          setFormData(prev => ({
            ...prev,
            sqft: Math.floor(numValue),
            price: totalPrice
          }));
        } else {
          setFormData(prev => ({
            ...prev,
            sqft: Math.floor(numValue)
          }));
        }
        return;
      }
  
      // Special handling for price
      if (name === 'price') {
        if (isPricePerUnit) {
          setPricePerUnit(numValue.toString());
          // Calculate total price if we have both price per unit and size
          if (formData.sqft) {
            const totalPrice = calculateTotalPrice(
              numValue,
              formData.sqft,
              true,
              i18n.language
            );
            setFormData(prev => ({ ...prev, price: totalPrice }));
          }
        } else {
          setFormData(prev => ({ ...prev, price: numValue }));
        }
        return;
      }
  
      // For bedrooms and bathrooms, ensure integers
      if (name === 'bedrooms' || name === 'bathrooms') {
        setFormData(prev => ({ ...prev, [name]: Math.floor(numValue) }));
        return;
      }
  
      // For other numeric fields
      setFormData(prev => ({ ...prev, [name]: numValue }));
      return;
    }
  
    // Handle property type changes
    if (name === 'propertyType') {
      if (!['House', 'Apartment'].includes(value)) {
        // Reset bedroom and bathroom values for non-residential properties
        setFormData(prev => ({
          ...prev,
          [name]: value,
          bedrooms: '',
          bathrooms: ''
        }));
        return;
      }
      
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
      return;
    }
  
    // Handle all other fields normally
    if (name === 'neighborhood') {
      const filtered = neighborhoods.filter(n => 
        n.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredNeighborhoods(filtered);
    }
  
    if (name === 'cityCountry') {
      const { city, country } = splitCityCountry(value);
      fetchNeighborhoods(country, city);
      setDefaultCoordinates(city, country);
      setFormData(prev => ({ 
        ...prev, 
        [name]: value,
        neighborhood: '' 
      }));
      return;
    }
  
    // Default case for all other fields
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleNeighborhoodSelect = (neighborhood) => {
    setFormData(prev => ({ 
      ...prev, 
      neighborhood
    }));
    setFilteredNeighborhoods([]);
    setShowNeighborhoodSuggestions(false);
  };

  

    // Update the handleImageUpload function
    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        const validFiles = files.filter(file => 
        file.size <= MAX_FILE_SIZE && ALLOWED_FILE_TYPES.includes(file.type)
        );
    
        const newImages = validFiles.map(file => ({
        existing: false,
        file,
        preview: URL.createObjectURL(file),
        name: file.name
        }));
    
        setImages(prevImages => [...prevImages, ...newImages]);
    
        if (files.length !== validFiles.length) {
        alert(t('Some files were not added due to size or type restrictions.'));
        }
    };

    // Update the removeImage function
    const removeImage = (index) => {
        setImages(prevImages => {
        const newImages = [...prevImages];
        if (!newImages[index].existing) {
            URL.revokeObjectURL(newImages[index].preview);
        }
        newImages.splice(index, 1);
        return newImages;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const token = getAuthToken();
          const { city, country } = splitCityCountry(formData.cityCountry);
          
          const formDataToSend = new FormData();
          
          // Add basic form fields, ensuring null values are handled properly
          formDataToSend.append('title', formData.title || '');
          formDataToSend.append('description', formData.description || '');
          formDataToSend.append('price', formData.price || '');
          formDataToSend.append('sqft', formData.sqft || '');
          formDataToSend.append('address', formData.address || '');
          formDataToSend.append('state', formData.state || '');
          formDataToSend.append('zipCode', formData.zipCode || '');
          formDataToSend.append('neighborhood', formData.neighborhood || '');
          formDataToSend.append('latitude', formData.latitude || '');
          formDataToSend.append('longitude', formData.longitude || '');
          formDataToSend.append('postType', formData.postType || '');
          formDataToSend.append('propertyType', formData.propertyType || '');
          formDataToSend.append('city', city);
          formDataToSend.append('country', country);
      
          // Handle residential property specific fields
          if (['House', 'Apartment'].includes(formData.propertyType)) {
            formDataToSend.append('bedrooms', formData.bedrooms || '');
            formDataToSend.append('bathrooms', formData.bathrooms || '');
          } else {
            formDataToSend.append('bedrooms', '');
            formDataToSend.append('bathrooms', '');
          }
      
            // Handle existing images with robust path cleaning
            const existingImages = images
            .filter(img => img.existing)
            .map(img => cleanImagePath(img.path));
            
            if (existingImages.length > 0) {
            console.log('Cleaned image paths:', existingImages); // For debugging
            existingImages.forEach(path => {
                formDataToSend.append('existingImages', path);
            });
            }
      
          // Handle new images
          const newImages = images.filter(img => !img.existing);
          newImages.forEach(img => {
            formDataToSend.append('images', img.file);
          });
      
          await updateListing(listing.id, formDataToSend, token);
          onSuccess();
        } catch (error) {
            console.error('Error updating listing:', error);
            setToast({
              message: t('Failed to update listing. Please try again.'),
              type: 'error'
            });
          }
        };

  const getLocation = (isExact) => {
    setIsLoadingMap(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData(prev => ({
            ...prev,
            latitude: isExact ? position.coords.latitude : Number(position.coords.latitude.toFixed(2)),
            longitude: isExact ? position.coords.longitude : Number(position.coords.longitude.toFixed(2)),
          }));
          setIsLoadingMap(false);
        },
        (error) => {
          console.error("Error getting location:", error);
          alert(t('Unable to get location. Please try again.'));
          setIsLoadingMap(false);
        },
        { enableHighAccuracy: isExact, maximumAge: isExact ? 0 : 600000 }
      );
    } else {
      alert(t('Geolocation is not supported by your browser.'));
      setIsLoadingMap(false);
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
      <ModalHeader title={t('Edit Property')} onClose={onClose} />
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <select name="postType" value={formData.postType  || ''} onChange={handleChange} required>
              <option value="">{t('Select Post Type')}</option>
              <option value="Sale">{t('For Sale')}</option>
              <option value="Rent">{t('For Rent')}</option>
            </select>
            <select name="propertyType" value={formData.propertyType || ''} onChange={handleChange} required>
              <option value="">{t('Select Property Type')}</option>
              <option value="House">{t('House')}</option>
              <option value="Apartment">{t('Apartment')}</option>
              <option value="Land">{t('Land')}</option>
              <option value="Warehouse">{t('Warehouse')}</option>
              <option value="Office">{t('Office')}</option>
              <option value="Building">{t('Building')}</option>
            </select>            
          <input
            type="text"
            name="title"
            value={formData.title || ''}
            onChange={handleChange}
            placeholder={t('Property Title')}
            required
          />
          <textarea
            name="description"
            value={formData.description || ''}
            onChange={handleChange}
            placeholder={t('Property Description')}
            required
          />
            <div className="user-filter">
            <label className="switch">
                <input
                type="checkbox"
                checked={isPricePerUnit}
                onChange={handlePriceToggle}
                />
                <span className="slider"></span>
            </label>
            <span className="filter-label">
                {isPricePerUnit ? 
                i18n.language === 'en' ? t('Price in sqft') : t('Price in m²') 
                : t('Total Price')}
            </span>
            </div>

            {/* Replace your existing price input with this */}
            <input
            type="number"
            name="price"
            value={isPricePerUnit ? pricePerUnit : formData.price || ''}
            onChange={handleChange}
            placeholder={isPricePerUnit ? 
                i18n.language === 'en' ? t('Price per sqft') : t('Price per m²')
                : t('Price')}
            min="0"
            step="1"
            required
            />

            {isPricePerUnit && formData.price > 0 && (
            <div className="calculated-price">
                {t('Total Price')}: {formData.price}
            </div>
            )}
          <input
            type="number"
            name="bedrooms"
            value={formData.bedrooms || ''}
            onChange={handleChange}
            placeholder={t('Bedrooms')}
            min="0"
            step="1"
          />
          <input
            type="number"
            name="bathrooms"
            value={formData.bathrooms || ''}
            onChange={handleChange}
            placeholder={t('Bathrooms')}
            min="0"
            step="1"
          />
          <input
            type="number"
            name="sqft"
            value={formData.sqft || ''}
            onChange={handleChange}
            placeholder={t('Size (sqft)')}
            min="0"
            step="1"
          />
          <input
            type="text"
            name="address"
            value={formData.address || ''}
            onChange={handleChange}
            placeholder={t('Address')}
          />          
          <select 
            name="cityCountry" 
            value={formData.cityCountry || ''} 
            onChange={handleChange}
            required
          >
            <option value="">{t('Select City, Country')}</option>
            {getCityCountryOptions().map(option => (
              <option key={option} value={option}>{t(option)}</option>
            ))}
          </select>
          <div className="autocomplete">
            <input
              type="text"
              name="neighborhood"
              value={formData.neighborhood || ''}
              onChange={handleChange}
              onFocus={handleNeighborhoodFocus}
              onBlur={handleNeighborhoodBlur}
              placeholder={t('Neighborhood')}
            />
            {showNeighborhoodSuggestions && formData.neighborhood && filteredNeighborhoods.length > 0 && (
              <ul className="autocomplete-items">
                {filteredNeighborhoods.map(n => (
                  <li key={n} onClick={() => handleNeighborhoodSelect(n)}>{n}</li>
                ))}
              </ul>
            )}
          </div>
          
          <div className="location-buttons">
            <button type="button" onClick={() => getLocation(true)} className="location-button" disabled={isLoadingMap}>
              {t('Exact Location')}
            </button>
            <button type="button" onClick={() => getLocation(false)} className="location-button" disabled={isLoadingMap}>
              {t('Approximate Location')}
            </button>
          </div>
          {isLoadingMap && <p>{t('Loading map...')}</p>}
          {(formData.latitude && formData.longitude) && !isLoadingMap && (
            <div className="coordinates-display">
              <p>{t('Latitude')}: {formData.latitude}</p>
              <p>{t('Longitude')}: {formData.longitude}</p>
            </div>
          )}

          <input
            type="file"
            multiple
            onChange={handleImageUpload}
            accept="image/*"
          />
            <div className="image-previews">
            {images.map((image, index) => (
                <div key={index} className="image-preview-item">
                <img 
                    src={image.preview} 
                    alt={`Thumbnail ${index + 1}`} 
                    className="image-thumbnail" 
                />
                <span className="image-name">
                    {image.existing ? `Image ${index + 1}` : image.name}
                </span>
                <button 
                    type="button" 
                    onClick={() => removeImage(index)} 
                    className="remove-image"
                >
                    {t('Remove')}
                </button>
                </div>
            ))}
            </div>
          <button type="submit" className="submit-button">{t('Update Property')}</button>
          </form>

          {toast && (
            <Toast
              message={toast.message}
              type={toast.type}
              onClose={() => setToast(null)}
              duration={5000}
              showOverlay={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

EditPostModal.propTypes = {
  listing: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default EditPostModal;