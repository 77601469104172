import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, formatSqft, 
          calculatePricePerSqft,
          countries, formatDatePost, 
          DEFAULT_IMAGE ,
          maskEmail
        } from '../utils/helpers';
import { BACKEND_URL,
          FRONTEND_URL, 
          apartment_image, 
          land_image, 
          house_image,  
        } from '../utils/helpers';
import { ChevronLeft, ChevronRight, 
          BedDouble, Bath, Home, User, Mail, 
          Phone, Edit, MapPinHouse, CircleX, 
          Clock, Trash2, PencilRuler,
          Banknote
        } from 'lucide-react';
import { getListing, deleteListing } from '../services/api';
import { getUser } from '../services/auth';
import ModalHeader from './ModalHeader';
import MetaTags from './MetaTags';



const getCountryCode = (listing) => {
  // If no listing or no country info, return default
  if (!listing) return 'CM';

  // Try getting country directly from listing
  if (listing.country) {
    const countryName = listing.country.trim();
    const countryEntry = Object.entries(countries).find(([name, _]) => 
      name.toLowerCase() === countryName.toLowerCase()
    );
    
    if (countryEntry) {
      return countryEntry[1].countryCode;
    }
  }

  // Fallback: try to determine from city if available
  if (listing.city) {
    const normalizedCity = listing.city.trim().toLowerCase();
    for (const [countryName, countryData] of Object.entries(countries)) {
      const cities = Object.keys(countryData.cities).map(city => city.toLowerCase());
      if (cities.includes(normalizedCity)) {
        return countryData.countryCode;
      }
    }
  }

  // Default fallback
  return 'CM';
};

// Update how the country code is used in the component
const ListingModal = ({ listing: initialListing, onClose, onEditClick, onDeleteSuccess }) => {
  const { t, i18n } = useTranslation();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [listing, setListing] = useState(initialListing);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCurrentUserOwner, setIsCurrentUserOwner] = useState(false);

  const getDefaultForType = (propertyType) => {
    switch (propertyType) {
      case 'Apartment':
        return apartment_image;
      case 'Land':
        return land_image;
      case 'House':
        return house_image;
      default:
        return DEFAULT_IMAGE;
    }
  };

  const getCleanImageUrl = (url) => {
    if (!url || url.trim() === '') {
      return getDefaultForType(listing?.propertyType);
    }
    
    if (url.startsWith('http')) {
      return url;
    }
    
    return `${BACKEND_URL}${url.replace(/^\/+/, '')}`;
  };


  useEffect(() => {
    const fetchFullListing = async () => {
      try {
        setIsLoading(true);
        const fullListing = await getListing(initialListing.id);
        if (fullListing) {
          const processedListing = {
            ...fullListing,
            images: fullListing.images?.length 
              ? fullListing.images.map(img => getCleanImageUrl(img))
              : [DEFAULT_IMAGE]
          };
          setListing(processedListing);
          
          const currentUser = getUser();
          const listingUserId = Number(fullListing.user_id);
          const currentUserId = Number(currentUser?.id);
          setIsCurrentUserOwner(currentUser && currentUserId === listingUserId);
        } else {
          setError('Failed to fetch full listing details');
        }
      } catch (err) {
        console.error('Error fetching listing details:', err);
        setError('An error occurred while fetching listing details');
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchFullListing();
  }, [initialListing.id]);

  const images = listing.images?.length > 0 
    ? listing.images 
    : [getCleanImageUrl(listing.imageUrl) || DEFAULT_IMAGE];

  // Prepare meta data for the listing only after we have the full listing data
  const listingMeta = !isLoading && !error ? {
    title: listing.title ? String(formatPrice(listing.price, getCountryCode(listing.country))) + ' - ' + String(listing.title) : '',
    description: listing.propertyType ? 
      `${String(listing.propertyType)} for ${String(listing.postType)} - ` +
      `${String(listing.bedrooms || 0)} bd, ${String(listing.bathrooms || 0)} ba, ` +
      `${String(formatSqft(listing.sqft, i18n.language))} - ` +
      `${String(listing.neighborhood || listing.address || '')}, ${String(listing.city || '')}`
      : '',
    image: images && images.length > 0 ? String(images[0]) : '',
    url: String(`${FRONTEND_URL}/search?listingId=${listing.id}`),
    type: 'realestate.listing'
  } : null;

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleDelete = async () => {
    if (window.confirm(t('Are you sure you want to delete this listing?'))) {
      try {
        await deleteListing(listing.id);
        onDeleteSuccess();
        onClose();
      } catch (error) {
        console.error('Error deleting listing:', error);
        alert(t('Failed to delete listing. Please try again.'));
      }
    }
  };

  if (isLoading) {
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <ModalHeader title={t('Loading...')} onClose={onClose} />
          <div className="modal-body">
            <p>{t('Loading...')}</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <ModalHeader title={t('Error')} onClose={onClose} />
          <div className="modal-body">
            <p>{error}</p>
          </div>
        </div>
      </div>
    );
  }

  const countryCode = getCountryCode(listing.country);

  return (
    <div className="modal-overlay" onClick={onClose}>
      {listingMeta && <MetaTags {...listingMeta} />}
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <ModalHeader title={listing.title} onClose={onClose} />
        <div className="modal-body">
          <div className="image-carousel">
            <img 
              src={images[currentImageIndex]} 
              alt={`${listing.title || t('Property')} - ${t('Image')} ${currentImageIndex + 1}`}
              onError={(e) => {
                console.log('Modal image load failed for:', listing.propertyType);
                e.target.src = getDefaultForType(listing.propertyType);
                e.target.onerror = null; // Prevent infinite loop
              }}
            />
            {images.length > 1 && (
              <>
                <button className="carousel-button prev" onClick={prevImage}>
                  <ChevronLeft size={24} />
                </button>
                <button className="carousel-button next" onClick={nextImage}>
                  <ChevronRight size={24} />
                </button>
                <div className="image-counter">
                  {currentImageIndex + 1} / {images.length}
                </div>
              </>
            )}
          </div>
          <div className="listing-details">
            <div className="price-container">
                <p className="price">
                  <Banknote size={18} /> {formatPrice(listing.price, countryCode)}
                  </p>
                <span className="price-per-unit">
                  <PencilRuler size={12} />
                  {calculatePricePerSqft(listing.price, listing.sqft, i18n.language, countryCode)}
                </span>
            </div>
            <p className="address">{`${listing.neighborhood || listing.address}`}</p>
            <div className="listing-info">
              <BedDouble size={16} /> <span>{listing.bedrooms} {t('bd')}</span>
              <Bath size={16} /> <span>{listing.bathrooms} {t('ba')}</span>
              <Home size={16} /> <span>{formatSqft(listing.sqft, i18n.language)}</span>
            </div>
            <p className="description">{listing.description}</p>
            <div className="additional-details">
              <p>
                <Clock size={16} /> 
                <strong> {t('Post Date')}:</strong> {formatDatePost(listing.created_at)}
              </p>
              <p><Home size={16} /> <strong>{t('Property Type')}:</strong> {listing.propertyType}</p>
              {listing.state && <p><MapPinHouse size={16} /> <strong>{t('State')}:</strong> {listing.state}</p>}
              <p><User size={16} /> <strong>{t('Poster Name')}:</strong> {listing.posterName}</p>
              <p>
                <Mail size={16} /> 
                <strong>{t('Poster Email')}:</strong>
                <a href={maskEmail(listing.posterEmail)}>
                  {maskEmail(listing.posterEmail)}
                  </a>
              </p>
              <p><Phone size={16} /> <strong>{t('Poster Phone')}:</strong> +{listing.posterPhone}</p>
            </div>
          </div>
          <div className="modal-buttons">
            {isCurrentUserOwner && (
              <>
                <button className="edit-button" onClick={() => onEditClick(listing)}>
                  <Edit size={12} /> <span>{t('Edit')}</span>
                </button> 
                <br/>
                <button className="delete-button" onClick={handleDelete}>
                  <Trash2 size={12} /> <span>{t('Delete')}</span>
                </button>
              </>
            )}
            <br/>
            <button className="close-button" onClick={onClose}>
              <CircleX size={12}/> <span>{t('Close')}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingModal;