import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="static-page">
      <div className="static-page-hero">
        <h1>{t('About Habittatt')}</h1>
      </div>
      <div className="static-page-content-wrapper">
        <div className="static-page-card">
          <h2>{t('Our Mission')}</h2>
          <p>{t('about.mission', 'Habittatt is dedicated to revolutionizing the real estate industry in West Africa by providing a transparent, efficient, and user-friendly platform for property transactions. We believe everyone deserves access to quality housing and investment opportunities.')}</p>

          <h2>{t('Our Vision')}</h2>
          <p>{t('about.vision', 'To become the leading real estate platform in West Africa, connecting property seekers with their dream homes and enabling property owners to reach a wider audience.')}</p>

          <h2>{t('Our Services')}</h2>
          <ul>
            <li>{t('about.services.listings', 'Comprehensive Property Listings')}</li>
            <li>{t('about.services.consulting', 'Real Estate Consulting')}</li>
            <li>{t('about.services.analysis', 'Market Analysis and Insights')}</li>
            <li>{t('about.services.support', '24/7 Customer Support')}</li>
            <li>{t('about.services.verification', 'Property Verification Services')}</li>
          </ul>

          <h2>{t('Why Choose Us')}</h2>
          <div className="features-grid">
            <div className="feature">
              <h3>{t('about.features.trust', 'Trust & Security')}</h3>
              <p>{t('about.features.trust.description', 'All listings are verified and sellers are thoroughly vetted to ensure a safe experience.')}</p>
            </div>
            <div className="feature">
              <h3>{t('about.features.coverage', 'Wide Coverage')}</h3>
              <p>{t('about.features.coverage.description', 'Extensive property listings across major cities in West Africa.')}</p>
            </div>
            <div className="feature">
              <h3>{t('about.features.support', 'Expert Support')}</h3>
              <p>{t('about.features.support.description', 'Our team of real estate experts is always ready to assist you.')}</p>
            </div>
          </div>

          <a href="https://arlinaconsulting.com" target="_blank" rel="noopener noreferrer" className="static-page-cta">
            {t('Visit our main website')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;