import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { createListing } from '../services/api';
import { getAuthToken } from '../services/auth';
import { 
  getCoordinates, 
  getCityCountryOptions, 
  getCityCountry,
  calculateTotalPrice, 
  calculatePricePerUnit 
} from '../utils/helpers';
import Toast from './Toast';
import ModalHeader from './ModalHeader';





// Property types that should show bedroom/bathroom fields
const RESIDENTIAL_TYPES = ['House', 'Apartment'];


const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

const splitCityCountry = (cityCountry) => {
  const [city, country] = cityCountry.split(', ');
  return { city, country };
};

const PostModal = ({ onClose, onSuccess, mapCenter }) => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    price: '',
    bedrooms: '',
    bathrooms: '',
    sqft: '',
    address: '',
    cityCountry: '',
    state: '',
    zipCode: '',
    neighborhood: '',
    latitude: '',
    longitude: '',
    postType: '',
    propertyType: '',
  });
  const [images, setImages] = useState([]);
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [filteredNeighborhoods, setFilteredNeighborhoods] = useState([]);
  const [showNeighborhoodSuggestions, setShowNeighborhoodSuggestions] = useState(false);
  // Add new state for price per unit toggle
  const [isPricePerUnit, setIsPricePerUnit] = useState(false);
  const [pricePerUnit, setPricePerUnit] = useState('');
  const { t, i18n } = useTranslation();


  const [toast, setToast] = useState(null);

  const handleNeighborhoodFocus = () => {
    setShowNeighborhoodSuggestions(true);
  };

  const showToast = (message, type = 'error') => {
    setToast({ message, type });
  };

  const handleNeighborhoodBlur = () => {
    // Use setTimeout to allow clicking on suggestions before hiding them
    setTimeout(() => setShowNeighborhoodSuggestions(false), 200);
  };

  // Update handlePriceToggle
  const handlePriceToggle = (e) => {
    const newIsPricePerUnit = e.target.checked;
    setIsPricePerUnit(newIsPricePerUnit);
    
    if (formData.sqft && formData.price) {
      if (newIsPricePerUnit) {
        // Calculate price per unit from current total price
        const newPricePerUnit = calculatePricePerUnit(
          formData.price, 
          formData.sqft, 
          true, 
          i18n.language
        );
        setPricePerUnit(newPricePerUnit.toString());
      } else {
        // Keep the total price as is
        setPricePerUnit('');
      }
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const cityCountry = searchParams.get('cityCountry');
    if (cityCountry) {
      setFormData(prev => ({ ...prev, cityCountry }));
      const { city, country } = splitCityCountry(cityCountry);
      fetchNeighborhoods(country, city);
      setDefaultCoordinates(city, country);
    } else if (mapCenter) {
      reverseGeocode(mapCenter.lat, mapCenter.lng);
    }
  }, [location, mapCenter]);

  const reverseGeocode = async (lat, lng) => {
    try {
      const { city, country } = await getCityCountry(lat, lng);
      const formattedCityCountry = `${city}, ${country}`;
      
      // Check if this city/country combination exists in our options
      const validOptions = getCityCountryOptions();
      const matchingOption = validOptions.find(option => 
        option.toLowerCase() === formattedCityCountry.toLowerCase()
      );
  
      setFormData(prev => ({ 
        ...prev, 
        cityCountry: matchingOption || '', // Use matching option if found, otherwise empty string
        latitude: lat,
        longitude: lng
      }));
  
      if (matchingOption) {
        const { city, country } = splitCityCountry(matchingOption);
        fetchNeighborhoods(country, city);
      }
    } catch (error) {
      console.error('Error in reverse geocoding:', error);
    }
  };

  const setDefaultCoordinates = (city, country) => {
    const coordinates = getCoordinates(country, city);
    setFormData(prev => ({
      ...prev,
      latitude: coordinates.lat,
      longitude: coordinates.lng
    }));
  };

  const fetchNeighborhoods = async (country, city) => {
    try {
      const response = await fetch(`/data/neighborhoods/${country.toLowerCase()}/${city.toLowerCase()}.json`);
      const data = await response.json();
      setNeighborhoods(Object.keys(data));
      setFilteredNeighborhoods(Object.keys(data));
    } catch (error) {
      console.error('Error fetching neighborhoods:', error);
      setNeighborhoods([]);
      setFilteredNeighborhoods([]);
    }
  };

  // Update the handleChange function
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (['price', 'bedrooms', 'bathrooms', 'sqft'].includes(name)) {
      // If the value is empty, set it to empty string (will be converted to null later)
      if (value === '') {
        if (name === 'price' && isPricePerUnit) {
          setPricePerUnit('');
        }
        setFormData(prev => ({ ...prev, [name]: '' }));
        return;
      }

      // Convert to number and check if it's negative
      const numValue = Number(value);
      if (numValue < 0) {
        return; // Ignore negative values
      }

      // Update sqft handling in handleChange
      if (name === 'sqft') {
        if (isPricePerUnit && pricePerUnit) {
          // Recalculate total price based on new size
          const totalPrice = calculateTotalPrice(
            pricePerUnit, 
            numValue, 
            true, 
            i18n.language
          );
          setFormData(prev => ({
            ...prev,
            sqft: Math.floor(numValue),
            price: totalPrice
          }));
        } else {
          setFormData(prev => ({
            ...prev,
            sqft: Math.floor(numValue)
          }));
        }
        return;
      }

      if (name === 'price') {
        if (isPricePerUnit) {
          setPricePerUnit(numValue.toString());
          // Calculate total price if we have both price per unit and size
          if (formData.sqft) {
            const totalPrice = calculateTotalPrice(
              numValue, 
              formData.sqft, 
              true, 
              i18n.language
            );
            setFormData(prev => ({ ...prev, price: totalPrice }));
          }
        } else {
          setFormData(prev => ({ ...prev, price: numValue }));
        }
        return;
      }

      // For bedrooms and bathrooms, ensure integers
      if (name === 'bedrooms' || name === 'bathrooms') {
        setFormData(prev => ({ ...prev, [name]: Math.floor(numValue) }));
        return;
      }

      // For other numeric fields, allow decimals
      setFormData(prev => ({ ...prev, [name]: numValue }));
      return;
    }

    // Handle property type changes
    if (name === 'propertyType') {
      // Clear bedroom and bathroom values when switching to non-residential property type
      if (!RESIDENTIAL_TYPES.includes(value)) {
        setFormData(prev => ({
          ...prev,
          [name]: value,
          bedrooms: '',
          bathrooms: ''
        }));
        return;
      }
    }

    // Handle other fields normally
    if (name === 'neighborhood') {
      const filtered = neighborhoods.filter(n => 
        n.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredNeighborhoods(filtered);
    }

    if (name === 'cityCountry') {
      const { city, country } = splitCityCountry(value);
      setFormData(prev => ({
        ...prev,
        [name]: value,
        neighborhood: '' // Reset neighborhood when city changes
      }));
      fetchNeighborhoods(country, city);
      setDefaultCoordinates(city, country);
      return;
    }

    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleNeighborhoodSelect = async (neighborhood) => {
    setFormData(prev => ({ 
      ...prev, 
      neighborhood
    }));
    setFilteredNeighborhoods([]);
    setShowNeighborhoodSuggestions(false);
  
    try {
      const { city, country } = splitCityCountry(formData.cityCountry);
      const response = await fetch(`/data/neighborhoods/${country.toLowerCase()}/${city.toLowerCase()}.json`);
      const data = await response.json();
      if (data[neighborhood]) {
        const [lat, lng] = data[neighborhood];
        setFormData(prev => ({
          ...prev,
          latitude: lat,
          longitude: lng
        }));
      }
    } catch (error) {
      console.error('Error fetching neighborhood coordinates:', error);
    }
  };


  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(file => 
      file.size <= MAX_FILE_SIZE && ALLOWED_FILE_TYPES.includes(file.type)
    );

    const newImages = validFiles.map(file => ({
      file,
      preview: URL.createObjectURL(file),
      name: file.name
    }));

    setImages(prevImages => [...prevImages, ...newImages]);

    if (files.length !== validFiles.length) {
      alert(t('Some files were not added due to size or type restrictions.'));
    }
  };

  const removeImage = (index) => {
    setImages(prevImages => {
      const newImages = [...prevImages];
      URL.revokeObjectURL(newImages[index].preview);
      newImages.splice(index, 1);
      return newImages;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    try {
      const token = getAuthToken();
      const { city, country } = splitCityCountry(formData.cityCountry);
      let finalLatitude = formData.latitude;
      let finalLongitude = formData.longitude;
  
      if (!finalLatitude || !finalLongitude) {
        const coordinates = getCoordinates(country, city);
        finalLatitude = coordinates.lat;
        finalLongitude = coordinates.lng;
      }
  
      const processedFormData = {
        ...formData,
        price: formData.price === '' ? null : Number(formData.price),
        bedrooms: formData.bedrooms === '' ? null : Number(formData.bedrooms),
        bathrooms: formData.bathrooms === '' ? null : Number(formData.bathrooms),
        sqft: formData.sqft === '' ? null : Number(formData.sqft),
        city,
        country,
        latitude: finalLatitude,
        longitude: finalLongitude,
        images: images.map(img => img.file)
      };
  
      delete processedFormData.cityCountry;
      await createListing(processedFormData, token);
      showToast(t('Property listed successfully!'), 'success');
      setTimeout(() => {
        if (onSuccess) {
          onSuccess();
        }
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error creating listing:', error);
      showToast(t('Failed to create listing. Please try again.'));
    }
  };

  const getLocation = (isExact) => {
    setIsLoadingMap(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData(prev => ({
            ...prev,
            latitude: isExact ? position.coords.latitude : Number(position.coords.latitude.toFixed(2)),
            longitude: isExact ? position.coords.longitude : Number(position.coords.longitude.toFixed(2)),
          }));
          setIsLoadingMap(false);
        },
        (error) => {
          console.error("Error getting location:", error);
          alert(t('Unable to get location. Please try again.'));
          setIsLoadingMap(false);
        },
        { enableHighAccuracy: isExact, maximumAge: isExact ? 0 : 600000 }
      );
    } else {
      alert(t('Geolocation is not supported by your browser.'));
      setIsLoadingMap(false);
    }
  };

  const validateForm = () => {
    if (!formData.title.trim()) {
      showToast(t('Please enter a title'));
      return false;
    }
    if (!formData.description.trim()) {
      showToast(t('Please enter a description'));
      return false;
    }
    if (!formData.price || formData.price <= 0) {
      showToast(t('Please enter a valid price'));
      return false;
    }
    if (!formData.cityCountry) {
      showToast(t('Please select a city and country'));
      return false;
    }
    if (!formData.postType) {
      showToast(t('Please select a post type'));
      return false;
    }
    if (!formData.propertyType) {
      showToast(t('Please select a property type'));
      return false;
    }
    if (RESIDENTIAL_TYPES.includes(formData.propertyType)) {
      if (!formData.bedrooms || formData.bedrooms < 0) {
        showToast(t('Please enter a valid number of bedrooms'));
        return false;
      }
      if (!formData.bathrooms || formData.bathrooms < 0) {
        showToast(t('Please enter a valid number of bathrooms'));
        return false;
      }
    }
    if (!formData.sqft || formData.sqft <= 0) {
      showToast(t('Please enter a valid size'));
      return false;
    }
    if (!formData.latitude || !formData.longitude) {
      showToast(t('Please set the property location'));
      return false;
    }
    return true;
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <ModalHeader title={t('Post New Property')} onClose={onClose} />
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <select name="postType" value={formData.postType} onChange={handleChange} required>
            <option value="">{t('Select Post Type')}</option>
            <option value="Sale">{t('For Sale')}</option>
            <option value="Rent">{t('For Rent')}</option>
          </select>
          <select name="propertyType" value={formData.propertyType} onChange={handleChange} required>
            <option value="">{t('Select Property Type')}</option>
            <option value="House">{t('House')}</option>
            <option value="Apartment">{t('Apartment')}</option>
            <option value="Land">{t('Land')}</option>
            <option value="Warehouse">{t('Warehouse')}</option>
            <option value="Office">{t('Office')}</option>
            <option value="Building">{t('Building')}</option>
          </select>          
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            placeholder={t('Property Title')}
            required
          />
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder={t('Property Description')}
            required
          />
          <div className="user-filter">
            <label className="switch">
              <input
                type="checkbox"
                checked={isPricePerUnit}
                onChange={handlePriceToggle}
              />
              <span className="slider"></span>
            </label>
            <span className="filter-label">
              {isPricePerUnit ? 
                i18n.language === 'en' ? t('Price in sqft') : t('Price in m²') 
                : t('Total Price')}
            </span>
          </div>
          <input
            type="number"
            name="price"
            value={isPricePerUnit ? pricePerUnit : formData.price}
            onChange={handleChange}
            placeholder={isPricePerUnit ? 
              i18n.language === 'en' ? t('Price per sqft') : t('Price per m²')
              : t('Price')}
            min="0"
            step="1"
            required
          />
          {isPricePerUnit && formData.price > 0 && (
            <div className="calculated-price">
              {t('Total Price')}: {formData.price}
            </div>
          )}
          {RESIDENTIAL_TYPES.includes(formData.propertyType) && (
            <>
              <input
                type="number"
                name="bedrooms"
                value={formData.bedrooms}
                onChange={handleChange}
                placeholder={t('Bedrooms')}
                min="0"
                step="1"
                onKeyDown={(e) => {
                  if (e.key === '-') e.preventDefault();
                }}
              />
              <input
                type="number"
                name="bathrooms"
                value={formData.bathrooms}
                onChange={handleChange}
                placeholder={t('Bathrooms')}
                min="0"
                step="1"
                onKeyDown={(e) => {
                  if (e.key === '-') e.preventDefault();
                }}
              />
            </>
          )}
          <input
            type="number"
            name="sqft"
            value={formData.sqft}
            onChange={handleChange}
            placeholder={t('Size (sqft)')}
            min="0"
            step="1"
            onKeyDown={(e) => {
              // Prevent entering negative sign
              if (e.key === '-') e.preventDefault();
            }}
          />
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            placeholder={t('Address')}
          />          
          <select 
            name="cityCountry" 
            value={formData.cityCountry} 
            onChange={handleChange}
            required
          >
            <option value="">{t('Select City, Country')}</option>
            {getCityCountryOptions().map(option => {
              const [city, country] = option.split(', ');
              const translatedCity = t(city);
              const translatedCountry = t(country);
              const displayText = `${translatedCity}, ${translatedCountry}`;
              
              return (
                <option key={option} value={option}>
                  {displayText}
                </option>
              );
            })}
          </select>
          <div className="autocomplete">
            <input
              type="text"
              name="neighborhood"
              value={formData.neighborhood}
              onChange={handleChange}
              onFocus={handleNeighborhoodFocus}
              onBlur={handleNeighborhoodBlur}
              placeholder={t('Neighborhood')}
            />
            {showNeighborhoodSuggestions && formData.neighborhood && filteredNeighborhoods.length > 0 && (
              <ul className="autocomplete-items">
                {filteredNeighborhoods.map(n => (
                  <li key={n} onClick={() => handleNeighborhoodSelect(n)}>{n}</li>
                ))}
              </ul>
            )}
          </div>
          
          <div className="location-buttons">
              <button type="button" onClick={() => getLocation(true)} className="location-button">
                {t('Exact Location')}
              </button>
              <button type="button" onClick={() => getLocation(false)} className="location-button">
                {t('Approximate Location')}
              </button>
          </div>
          {isLoadingMap && <p>{t('Loading map...')}</p>}
          {(formData.latitude && formData.longitude) && (
            <div className="coordinates-display">
              <p>{t('Latitude')}: {Number(formData.latitude).toFixed(6)}</p>
              <p>{t('Longitude')}: {Number(formData.longitude).toFixed(6)}</p>
            </div>
          )}
          <input
            type="file"
            multiple
            onChange={handleImageUpload}
            accept="image/*"
          />
          <div className="image-previews">
            {images.map((image, index) => (
              <div key={index} className="image-preview-item">
                <img src={image.preview} alt={`Thumbnail ${index}`} className="image-thumbnail" />
                <span className="image-name">{image.name}</span>
                <button type="button" onClick={() => removeImage(index)} className="remove-image">
                  {t('Remove')}
                </button>
              </div>
            ))}
          </div>
          <button type="submit" className="submit-button">{t('Post Property')}</button>
        </form>
        <br/>
        <button onClick={onClose} className="close-button">{t('Close')}</button>

        {toast && (
            <Toast
              message={toast.message}
              type={toast.type}
              onClose={() => setToast(null)}
              duration={5000}
              showOverlay={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

PostModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  mapCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
};

export default PostModal;