import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heart, BedDouble, 
          Bath, Home, 
          PencilRuler,
          Banknote 
        } from 'lucide-react';
import { formatPrice, formatSqft, 
          calculatePricePerSqft, 
          capitalizeFirstLetter, 
          countries,
          apartment_image, 
          land_image, 
          house_image 
        } from '../utils/helpers';
import { BACKEND_URL, DEFAULT_IMAGE } from '../utils/helpers';

const getCountryFromCity = (cityName) => {
  const normalizedCityName = cityName.trim().toLowerCase();
  
  for (const [countryName, countryData] of Object.entries(countries)) {
    const cities = Object.keys(countryData.cities).map(city => city.toLowerCase());
    if (cities.includes(normalizedCityName)) {
      return countryData.countryCode;
    }
  }
  
  return 'CM';
};

const getCountryCode = (listing) => {
  if (listing.country) {
    const normalizedCountryName = listing.country.trim();
    const country = Object.entries(countries).find(([name, data]) => 
      name.toLowerCase() === normalizedCountryName.toLowerCase()
    );
    
    if (country) {
      return country[1].countryCode;
    }
  }
  
  if (listing.city) {
    return getCountryFromCity(listing.city);
  }
  
  return 'CM';
};

const ListingCard = ({ listing, onClick, onMouseEnter, onMouseLeave, isHovered, id }) => {
  const getDefaultForType = (propertyType) => {
    switch (propertyType) {
      case 'Apartment':
        return apartment_image;
      case 'Land':
        return land_image;
      case 'House':
        return house_image;
      default:
        return DEFAULT_IMAGE;
    }
  };

  const { t, i18n } = useTranslation();
  
  const countryCode = getCountryCode(listing);
  
  const getCleanImageUrl = (url) => {
    if (!url || url.trim() === '') {
      return DEFAULT_IMAGE;
    }
    
    if (url.startsWith('http')) {
      return url;
    }
    
    const cleanPath = url.replace(/^\/+/, '');
    return `${BACKEND_URL}${cleanPath}`;
  };

  return (
    <div 
      id={id}
      className={`listing-card ${isHovered ? 'hovered' : ''}`} 
      onClick={onClick ? () => onClick(listing) : undefined}
      onMouseEnter={() => onMouseEnter(listing)}
      onMouseLeave={onMouseLeave}
    >
      <img 
        src={getCleanImageUrl(listing.imageUrl)} 
        alt={listing.title || t('Property')}
        onError={(e) => {
          e.target.src = getDefaultForType(listing.propertyType);
          e.target.onerror = null; // Prevent infinite loop if default also fails
        }}
      />
      <div className="listing-details">
        <div className="price-container">
          <h3><Banknote size={18} /> {formatPrice(listing.price, countryCode)}</h3>
          <span className="price-per-unit">
            <PencilRuler size={12} />
            {calculatePricePerSqft(listing.price, listing.sqft, i18n.language, countryCode)}
          </span>
        </div>
        <br/>

        <div className="listing-info">
          <BedDouble size={16} /> <span>{listing.bedrooms} {t('bd')}</span>
          <Bath size={16} /> <span>{listing.bathrooms} {t('ba')}</span>
          <Home size={16} /> <span>{formatSqft(listing.sqft, i18n.language)}</span>
        </div>
        <p className="listing-address">{`${listing.neighborhood || listing.address}, ${capitalizeFirstLetter(listing.city)}`}</p>
      </div>
    </div>
  );
};

export default ListingCard;