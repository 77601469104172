import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleMap, useJsApiLoader, Marker, OverlayView } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from '../utils/helpers';

const MapView = ({ listings, onMarkerClick, hoveredListing, onMarkerHover, onMarkerLeave, center }) => {
  const { t } = useTranslation();
  const [zoom, setZoom] = useState(11);
  const [map, setMap] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  });

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleZoomChanged = () => {
    if (map) {
      setZoom(map.getZoom());
    }
  };

  const mapStyles = {
    height: "100%",
    width: "100%"
  };

  const formatPrice = (price) => {
    if (price >= 1000000000) return `${(price / 1000000000).toFixed(1)}B`;
    if (price >= 1000000) return `${(price / 1000000).toFixed(1)}M`;
    if (price >= 1000) return `${(price / 1000).toFixed(0)}K`;
    return price.toString();
  };

  const dotIcon = {
    path: 0,
    fillColor: '#FF0000',
    fillOpacity: 1,
    strokeWeight: 0,
    scale: 6
  };

  if (!isLoaded) {
    return <div className="map-loading">{t('Loading map...')}</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={mapStyles}
      zoom={zoom}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onZoomChanged={handleZoomChanged}
    >
      {listings.map(listing => (
        <React.Fragment key={listing.id}>
          {zoom < 15 ? (
            <Marker
              position={{ lat: listing.lat, lng: listing.lng }}
              icon={dotIcon}
              onClick={() => onMarkerClick(listing)}
              onMouseOver={() => onMarkerHover(listing)}
              onMouseOut={onMarkerLeave}
            />
          ) : (
            <OverlayView
              position={{ lat: listing.lat, lng: listing.lng }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div style={{
                background: '#FF0000',
                color: 'white',
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: '12px',
                fontWeight: 'bold',
                boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                display: 'inline-block',
              }}
              onClick={() => onMarkerClick(listing)}
              onMouseOver={() => onMarkerHover(listing)}
              onMouseOut={onMarkerLeave}
              >
                {formatPrice(listing.price)}
              </div>
            </OverlayView>
          )}
          {zoom < 15 && hoveredListing && hoveredListing.id === listing.id && (
            <OverlayView
              position={{ lat: listing.lat, lng: listing.lng }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div style={{
                background: '#FF0000',
                color: 'white',
                padding: '6px 8px',
                borderRadius: '4px',
                fontSize: '14px',
                fontWeight: 'bold',
                boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
                position: 'relative',
                bottom: '40px',
                transform: 'translateX(-50%)',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                textAlign: 'center',
                minWidth: '50px'
              }}>
                {formatPrice(listing.price)}
              </div>
            </OverlayView>
          )}
        </React.Fragment>
      ))}
    </GoogleMap>
  );
};

export default React.memo(MapView);