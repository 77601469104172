import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      // Global
      "HomeFindr": "Habittatt",
      "Loading": "Loading...",
      "Close": "Close",
      "Edit": "Edit",
      "Delete": "Delete",
      "Apply": "Apply",
      "Clear": "Clear",
      "Submit": "Submit",
      "Cancel": "Cancel",
      "Save": "Save",
      "Error": "Error",
      "Success": "Success",

      // Auth
      "Sign In": "Sign In",
      "Sign Up": "Sign Up",
      "Sign Out": "Sign Out",
      "Email": "Email",
      "Password": "Password",
      "Full Name": "Full Name",
      "Phone Number": "Phone Number",
      "Select Country": "Select Country",
      "Account": "Account",
      "Profile updated successfully": "Profile updated successfully",
      "Failed to update profile": "Failed to update profile",

      // Property Metrics
      "bd": "bd",
      "ba": "ba",
      "sqft": "sqft",
      "Price": "Price",
      "Bedrooms": "Bedrooms",
      "Bathrooms": "Bathrooms",
      "Size": "Size",
      "Size (sqft)": "Size (sqft)",

      // Property Types
      "House": "House",
      "Apartment": "Apartment",
      "Land": "Land",
      "Warehouse": "Warehouse",
      "Office": "Office",
      "Building": "Building",

      // Listing Status
      "For Sale": "For Sale",
      "For Rent": "For Rent",
      "Sale": "Sale",
      "Rent": "Rent",

      // Search & Filters
      "Enter location, ZIP, or neighborhood": "Enter location, ZIP, or neighborhood",
      "Search": "Search",
      "Find !": "Find !",
      "Property Type": "Property Type",
      "Select Property Type": "Select Property Type",
      "Hide Filters": "Hide Filters",
      "Show Filters": "Show Filters",
      "Clear Filters": "Clear Filters",
      "Show only my listings": "Show only my listings",
      "No listings in this area": "No listings in this area",
      "Select City, Country": "Select City, Country",
      // ... existing English translations ...
      "Select Neighborhoods": "Select Neighborhoods",
      "Neighborhoods": "Neighborhoods",
      // Add these for completeness
      "No neighborhoods found": "No neighborhoods found",
      "All neighborhoods": "All neighborhoods",

      // Property Details
      "Property Title": "Property Title",
      "Property Description": "Property Description",
      "Address": "Address",
      "Neighborhood": "Neighborhood",
      "State": "State",
      "City": "City",
      "Country": "Country",
      "ZIP Code": "ZIP Code",
      "Location Details": "Location Details",
      "Exact Location": "Exact Location",
      "Approximate Location": "Approximate Location",
      "Latitude": "Latitude",
      "Longitude": "Longitude",
      "Post Date": "Post Date",
      "Poster Name": "Poster Name",
      "Poster Email": "Poster Email",
      "Poster Phone": "Poster Phone",

      // Property Actions
      "Post Property": "Post Property",
      "Update Property": "Update Property",
      "Edit Property": "Edit Property",
      "Post New Property": "Post New Property",
      "Select Post Type": "Select Post Type",
      "Property created successfully": "Property created successfully",
      "Failed to create property": "Failed to create property",
      "Property updated successfully": "Property updated successfully",
      "Failed to update property": "Failed to update property",
      "Are you sure you want to delete this listing?": "Are you sure you want to delete this listing?",

      // Images
      "Remove": "Remove",
      "Add Images": "Add Images",
      "Some files were not added due to size or type restrictions": "Some files were not added due to size or type restrictions",
      "Loading map...": "Loading map...",

      // Footer
      "All rights reserved": "All rights reserved",
      "About": "About",
      "Contact": "Contact",
      "Terms of Service": "Terms of Service",
      "Privacy Policy": "Privacy Policy",

      // Home Page
      "Welcome to Habittatt": "Welcome to Habittatt",
      "Find your dream home or perfect rental property": "Find your dream home or perfect rental property",
      "Explore beautiful homes and properties available in {{country}}": "Explore beautiful homes and properties available in {{country}}",
      "Explore": "Explore",

      // About Page
      "Our Mission": "Our Mission",
      "Our Services": "Our Services",
      "Why Choose Us": "Why Choose Us",
      "Visit our main website": "Visit our main website",

      // Contact Page
      "How to Contact Us": "How to Contact Us",
      "Contact Information": "Contact Information",
      "Website": "Website",
      "For customer and technical support": "For customer and technical support",
      "Help Center": "Help Center",

      // Countries
      "Cameroon": "Cameroon",
      "Ivory Coast": "Ivory Coast",
      "Ghana": "Ghana",
      "Mali": "Mali",
      "Senegal": "Senegal",
      "Benin": "Benin",

      // Cities
      "Douala": "Douala",
      "Yaounde": "Yaounde",
      "Abidjan": "Abidjan",
      "Yamoussoukro": "Yamoussoukro",
      "Accra": "Accra",
      "Kumasi": "Kumasi",
      "Bamako": "Bamako",
      "Dakar": "Dakar",
      "Saint-Louis": "Saint-Louis",
      "Cotonou": "Cotonou",
      "Porto-Novo": "Porto-Novo",

      // Price per unit
      "Price in sqft": "Price in sqft",
      "Price per sqft": "Price per sqft",
      "Total Price": "Total Price",

        // About Page
      "About Habittatt": "About Habittatt",
      "Our Mission": "Our Mission",
      "Our Vision": "Our Vision",
      "Our Services": "Our Services",
      "Why Choose Us": "Why Choose Us",
      "Visit our main website": "Visit our main website",
      "about.mission": "Habittatt is dedicated to revolutionizing the real estate industry in West Africa by providing a transparent, efficient, and user-friendly platform for property transactions. We believe everyone deserves access to quality housing and investment opportunities.",
      "about.vision": "To become the leading real estate platform in West Africa, connecting property seekers with their dream homes and enabling property owners to reach a wider audience.",
      "about.services.listings": "Comprehensive Property Listings",
      "about.services.consulting": "Real Estate Consulting",
      "about.services.analysis": "Market Analysis and Insights",
      "about.services.support": "24/7 Customer Support",
      "about.services.verification": "Property Verification Services",
      "about.features.trust": "Trust & Security",
      "about.features.trust.description": "All listings are verified and sellers are thoroughly vetted to ensure a safe experience.",
      "about.features.coverage": "Wide Coverage",
      "about.features.coverage.description": "Extensive property listings across major cities in West Africa.",
      "about.features.support": "Expert Support",
      "about.features.support.description": "Our team of real estate experts is always ready to assist you.",

      // Contact Page
      "How to Contact Us": "How to Contact Us",
      "Contact Information": "Contact Information",
      "Website": "Website",
      "For customer and technical support": "For customer and technical support",
      "Help Center": "Help Center",
      "contact.intro": "We're here to assist you with any questions or inquiries about our real estate services. Our dedicated team is ready to help you find your perfect property or address any concerns.",
      "contact.phone": "Phone",
      "Office Locations": "Office Locations",
      "contact.offices.cameroon": "Cameroon Office",
      "contact.offices.cameroon.address": "123 Main Street, Douala, Cameroon",
      "contact.offices.ivorycoast": "Ivory Coast Office",
      "contact.offices.ivorycoast.address": "456 Avenue, Abidjan, Ivory Coast",
      "Business Hours": "Business Hours",
      "contact.hours": "Monday to Friday: 8:00 AM - 6:00 PM",
      "contact.hours.weekend": "Saturday: 9:00 AM - 2:00 PM",
      "contact.emergency": "Emergency Support",
      "contact.emergency.description": "For urgent matters outside business hours, please contact our 24/7 emergency support line:",
      "contact.emergency.phone": "Emergency Hotline",

      // Privacy Policy
      "privacy.intro": "At Habittatt, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information.",
      "privacy.collection.title": "1. Information We Collect",
      "privacy.collection.description": "We collect information that you provide directly to us, including:",
      "privacy.collection.info.personal": "Name and contact information",
      "privacy.collection.info.login": "Login credentials",
      "privacy.collection.info.property": "Property details and images",
      "privacy.collection.info.payment": "Payment information",
      "privacy.use.title": "2. How We Use Your Information",
      "privacy.use.description": "We use the collected information to:",
      "privacy.use.purpose.service": "Provide and improve our services",
      "privacy.use.purpose.transactions": "Process transactions",
      "privacy.use.purpose.communication": "Communicate with you",
      "privacy.use.purpose.experience": "Personalize your experience",
      "privacy.sharing.title": "3. Information Sharing",
      "privacy.sharing.description": "We may share your information with:",
      "privacy.sharing.with.providers": "Service providers",
      "privacy.sharing.with.users": "Other users for transactions",
      "privacy.sharing.with.legal": "Legal authorities when required",
      "privacy.contact.title": "4. Contact Us",
      "privacy.contact.description": "For privacy-related questions, please contact us at:",

      // Terms of Service
      "terms.intro": "Welcome to Habittatt. By using our services, you agree to comply with and be bound by the following terms and conditions:",
      "terms.acceptance.title": "1. Acceptance of Terms",
      "terms.acceptance.description": "By accessing or using the Habittatt platform, you agree to be bound by these Terms of Service and all applicable laws and regulations.",
      "terms.service.title": "2. Use of Service",
      "terms.service.description": "You agree to use our service for lawful purposes only and in a way that does not infringe upon the rights of others.",
      "terms.accounts.title": "3. User Accounts",
      "terms.accounts.description": "You are responsible for maintaining the confidentiality of your account and password.",
      "terms.listings.title": "4. Property Listings",
      "terms.listings.accuracy": "All property information must be accurate and current",
      "terms.listings.ownership": "You must have the right to list the property",
      "terms.listings.images": "Images must be actual photos of the property",
      "terms.payments.title": "5. Payments and Fees",
      "terms.payments.description": "Details about our fee structure and payment terms.",
      "terms.liability.title": "6. Limitation of Liability",
      "terms.liability.description": "Habittatt shall not be liable for any indirect, incidental, special, consequential or punitive damages.",
      "terms.changes.title": "7. Changes to Terms",
      "terms.changes.description": "We reserve the right to modify these terms at any time. Your continued use of Habittatt indicates acceptance of changes.",
      "terms.contact.title": "8. Contact Information",
      "terms.contact.description": "For questions about these Terms, please contact us at:",

    }
  },
  fr: {
    translation: {
        // Global
        "HomeFindr": "Habittatt",
        "Loading": "Chargement...",
        "Close": "Fermer",
        "Edit": "Modifier",
        "Delete": "Supprimer",
        "Apply": "Appliquer",
        "Clear": "Effacer",
        "Submit": "Soumettre",
        "Cancel": "Annuler",
        "Save": "Enregistrer",
        "Error": "Erreur",
        "Success": "Succès",

        // Auth
        "Sign In": "Se Connecter",
        "Sign Up": "S'inscrire",
        "Sign Out": "Se Déconnecter",
        "Email": "E-mail",
        "Password": "Mot de passe",
        "Full Name": "Nom complet",
        "Phone Number": "Numéro de téléphone",
        "Select Country": "Sélectionner le pays",
        "Account": "Compte",
        "Profile updated successfully": "Profil mis à jour avec succès",
        "Failed to update profile": "Échec de la mise à jour du profil",

        // Property Metrics
        "bd": "ch",
        "ba": "sdb",
        "sqft": "m²",
        "Price": "Prix",
        "Bedrooms": "Chambres",
        "Bathrooms": "Salles de bain",
        "Size": "Superficie",
        "Size (sqft)": "Superficie (m²)",

        // Property Types
        "House": "Maison",
        "Apartment": "Appartement",
        "Land": "Terrain",
        "Warehouse": "Entrepôt",
        "Office": "Bureau",
        "Building": "Immeuble",

        // Listing Status
        "For Sale": "À Vendre",
        "For Rent": "À Louer",
        "Sale": "Vente",
        "Rent": "Location",

        // Search & Filters
        "Enter location, ZIP, or neighborhood": "Entrez un lieu, code postal ou quartier",
        "Search": "Rechercher",
        "Find !": "Trouver !",
        "Property Type": "Type de bien",
        "Select Property Type": "Sélectionner le type de bien",
        "Hide Filters": "Masquer les filtres",
        "Show Filters": "Afficher les filtres",
        "Clear Filters": "Effacer les filtres",
        "Show only my listings": "Afficher uniquement mes annonces",
        "No listings in this area": "Aucune annonce dans cette zone",
        "Select City, Country": "Sélectionner Ville, Pays",
        // ... existing French translations ...
        "Select Neighborhoods": "Sélectionner les quartiers",
        "Neighborhoods": "Quartiers",
        // Add these for completeness
        "No neighborhoods found": "Aucun quartier trouvé",
        "All neighborhoods": "Tous les quartiers",

        // Property Details
        "Property Title": "Titre de la propriété",
        "Property Description": "Description de la propriété",
        "Address": "Adresse",
        "Neighborhood": "Quartier",
        "State": "État/Région",
        "City": "Ville",
        "Country": "Pays",
        "ZIP Code": "Code postal",
        "Location Details": "Détails de l'emplacement",
        "Exact Location": "Emplacement exact",
        "Approximate Location": "Emplacement approximatif",
        "Latitude": "Latitude",
        "Longitude": "Longitude",
        "Post Date": "Date de publication",
        "Poster Name": "Nom de l'annonceur",
        "Poster Email": "Email de l'annonceur",
        "Poster Phone": "Téléphone de l'annonceur",

        // Property Actions
        "Post Property": "Publier une propriété",
        "Update Property": "Mettre à jour la propriété",
        "Edit Property": "Modifier la propriété",
        "Post New Property": "Publier une nouvelle propriété",
        "Select Post Type": "Sélectionner le type d'annonce",
        "Property created successfully": "Propriété créée avec succès",
        "Failed to create property": "Échec de la création de la propriété",
        "Property updated successfully": "Propriété mise à jour avec succès",
        "Failed to update property": "Échec de la mise à jour de la propriété",
        "Are you sure you want to delete this listing?": "Êtes-vous sûr de vouloir supprimer cette annonce ?",

        // Images
        "Remove": "Supprimer",
        "Add Images": "Ajouter des images",
        "Some files were not added due to size or type restrictions": "Certains fichiers n'ont pas été ajoutés en raison des restrictions de taille ou de type",
        "Loading map...": "Chargement de la carte...",

        // Footer
        "All rights reserved": "Tous droits réservés",
        "About": "À Propos",
        "Contact": "Contact",
        "Terms of Service": "Conditions d'Utilisation",
        "Privacy Policy": "Politique de Confidentialité",

        // Home Page
        "Welcome to Habittatt": "Bienvenue sur Habittatt",
        "Find your dream home or perfect rental property": "Trouvez la maison de vos rêves ou la location parfaite",
        "Explore beautiful homes and properties available in {{country}}": "Découvrez de belles maisons et propriétés disponibles en {{country}}",
        "Explore": "Explorer",

        // About Page
        "Our Mission": "Notre Mission",
        "Our Services": "Nos Services",
        "Why Choose Us": "Pourquoi Nous Choisir",
        "Visit our main website": "Visitez notre site principal",

        // Contact Page
        "How to Contact Us": "Comment Nous Contacter",
        "Contact Information": "Informations de Contact",
        "Website": "Site Web",
        "For customer and technical support": "Pour le support client et technique",
        "Help Center": "Centre d'Aide",

        // Countries
        "Cameroon": "Cameroun",
        "Ivory Coast": "Côte d'Ivoire",
        "Ghana": "Ghana",
        "Mali": "Mali",
        "Senegal": "Sénégal",
        "Benin": "Bénin",

        // Cities
        "Douala": "Douala",
        "Yaounde": "Yaoundé",
        "Abidjan": "Abidjan",
        "Yamoussoukro": "Yamoussoukro",
        "Accra": "Accra",
        "Kumasi": "Kumasi",
        "Bamako": "Bamako",
        "Dakar": "Dakar",
        "Saint-Louis": "Saint-Louis",
        "Cotonou": "Cotonou",
        "Porto-Novo": "Porto-Novo",

        // Price per unit
        "Price in m²": "Prix en m²",
        "Price per m²": "Prix par m²",
        "Total Price": "Prix total",

        // About Page
        "About Habittatt": "À Propos de Habittatt",
        "Our Mission": "Notre Mission",
        "Our Vision": "Notre Vision",
        "Our Services": "Nos Services",
        "Why Choose Us": "Pourquoi Nous Choisir",
        "Visit our main website": "Visitez notre site principal",
        "about.mission": "Habittatt se consacre à révolutionner l'industrie immobilière en Afrique de l'Ouest en fournissant une plateforme transparente, efficace et conviviale pour les transactions immobilières. Nous croyons que chacun mérite l'accès à un logement de qualité et à des opportunités d'investissement.",
        "about.vision": "Devenir la principale plateforme immobilière en Afrique de l'Ouest, connectant les chercheurs de propriétés à la maison de leurs rêves et permettant aux propriétaires d'atteindre un public plus large.",
        "about.services.listings": "Annonces Immobilières Complètes",
        "about.services.consulting": "Conseil Immobilier",
        "about.services.analysis": "Analyse et Aperçus du Marché",
        "about.services.support": "Support Client 24/7",
        "about.services.verification": "Services de Vérification des Propriétés",
        "about.features.trust": "Confiance & Sécurité",
        "about.features.trust.description": "Toutes les annonces sont vérifiées et les vendeurs sont minutieusement contrôlés pour garantir une expérience sûre.",
        "about.features.coverage": "Large Couverture",
        "about.features.coverage.description": "Vaste catalogue de propriétés dans les principales villes d'Afrique de l'Ouest.",
        "about.features.support": "Support Expert",
        "about.features.support.description": "Notre équipe d'experts immobiliers est toujours prête à vous aider.",

        // Contact Page
        "How to Contact Us": "Comment Nous Contacter",
        "Contact Information": "Informations de Contact",
        "Website": "Site Web",
        "For customer and technical support": "Pour le support client et technique",
        "Help Center": "Centre d'Aide",
        "contact.intro": "Nous sommes là pour vous aider avec toutes vos questions concernant nos services immobiliers. Notre équipe dévouée est prête à vous aider à trouver la propriété parfaite ou à répondre à vos préoccupations.",
        "contact.phone": "Téléphone",
        "Office Locations": "Nos Bureaux",
        "contact.offices.cameroon": "Bureau au Cameroun",
        "contact.offices.cameroon.address": "123 Rue Principale, Douala, Cameroun",
        "contact.offices.ivorycoast": "Bureau en Côte d'Ivoire",
        "contact.offices.ivorycoast.address": "456 Avenue, Abidjan, Côte d'Ivoire",
        "Business Hours": "Heures d'Ouverture",
        "contact.hours": "Lundi à Vendredi: 8h00 - 18h00",
        "contact.hours.weekend": "Samedi: 9h00 - 14h00",
        "contact.emergency": "Support d'Urgence",
        "contact.emergency.description": "Pour les questions urgentes en dehors des heures de bureau, veuillez contacter notre ligne d'assistance d'urgence 24/7:",
        "contact.emergency.phone": "Ligne d'Urgence",

        // Privacy Page
        "Privacy Policy": "Politique de Confidentialité",
        "privacy.intro": "Chez Habittatt, nous nous engageons à protéger votre vie privée et à assurer la sécurité de vos informations personnelles. Cette Politique de Confidentialité explique comment nous collectons, utilisons, divulguons et protégeons vos informations.",
        "privacy.collection.title": "1. Informations que Nous Collectons",
        "privacy.collection.description": "Nous collectons les informations que vous nous fournissez directement, notamment :",
        "privacy.collection.info.personal": "Nom et coordonnées",
        "privacy.collection.info.login": "Identifiants de connexion",
        "privacy.collection.info.property": "Détails et images des propriétés",
        "privacy.collection.info.payment": "Informations de paiement",
        "privacy.use.title": "2. Comment Nous Utilisons Vos Informations",
        "privacy.use.description": "Nous utilisons les informations collectées pour :",
        "privacy.use.purpose.service": "Fournir et améliorer nos services",
        "privacy.use.purpose.transactions": "Traiter les transactions",
        "privacy.use.purpose.communication": "Communiquer avec vous",
        "privacy.use.purpose.experience": "Personnaliser votre expérience",
        "privacy.sharing.title": "3. Partage d'Informations",
        "privacy.sharing.description": "Nous pouvons partager vos informations avec :",
        "privacy.sharing.with.providers": "Les prestataires de services",
        "privacy.sharing.with.users": "D'autres utilisateurs pour les transactions",
        "privacy.sharing.with.legal": "Les autorités légales lorsque requis",
        "privacy.security.title": "4. Sécurité des Données",
        "privacy.security.description": "Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos informations contre tout accès non autorisé ou toute perte accidentelle.",
        "privacy.rights.title": "5. Vos Droits",
        "privacy.rights.description": "Vous avez le droit d'accéder, de corriger ou de supprimer vos informations personnelles.",
        "privacy.rights.list.access": "Accéder à vos données personnelles",
        "privacy.rights.list.correct": "Corriger vos informations inexactes",
        "privacy.rights.list.delete": "Demander la suppression de vos données",
        "privacy.rights.list.restrict": "Restreindre le traitement de vos données",
        "privacy.cookies.title": "6. Utilisation des Cookies",
        "privacy.cookies.description": "Nous utilisons des cookies pour améliorer votre expérience sur notre site.",
        "privacy.updates.title": "7. Mises à Jour de la Politique",
        "privacy.updates.description": "Nous nous réservons le droit de modifier cette politique à tout moment. Les modifications seront publiées sur cette page.",
        "privacy.contact.title": "8. Nous Contacter",
        "privacy.contact.description": "Pour toute question concernant la confidentialité, contactez-nous à :",

        // Terms of Service Translations
        "Terms of Service": "Conditions d'Utilisation",
        "terms.intro": "Bienvenue sur Habittatt. En utilisant nos services, vous acceptez de respecter et d'être lié par les conditions générales suivantes :",
        "terms.acceptance.title": "1. Acceptation des Conditions",
        "terms.acceptance.description": "En accédant ou en utilisant la plateforme Habittatt, vous acceptez d'être lié par ces Conditions d'Utilisation et toutes les lois et réglementations applicables.",
        "terms.service.title": "2. Utilisation du Service",
        "terms.service.description": "Vous acceptez d'utiliser notre service uniquement à des fins légales et d'une manière qui ne porte pas atteinte aux droits d'autrui.",
        "terms.accounts.title": "3. Comptes Utilisateurs",
        "terms.accounts.description": "Vous êtes responsable du maintien de la confidentialité de votre compte et de votre mot de passe.",
        "terms.accounts.responsibility": "Vous acceptez la responsabilité de toutes les activités qui se produisent sous votre compte.",
        "terms.listings.title": "4. Annonces Immobilières",
        "terms.listings.accuracy": "Toutes les informations sur les propriétés doivent être exactes et à jour",
        "terms.listings.ownership": "Vous devez avoir le droit de publier la propriété",
        "terms.listings.images": "Les images doivent être des photos réelles de la propriété",
        "terms.listings.rules": "Règles pour la publication d'annonces :",
        "terms.listings.rules.accurate": "Informations précises et véridiques",
        "terms.listings.rules.rights": "Droits légaux de publication",
        "terms.listings.rules.photos": "Photos authentiques uniquement",
        "terms.payments.title": "5. Paiements et Frais",
        "terms.payments.description": "Détails sur notre structure tarifaire et conditions de paiement.",
        "terms.payments.fees": "Frais applicables :",
        "terms.payments.fees.listing": "Frais de publication d'annonce",
        "terms.payments.fees.premium": "Services premium",
        "terms.payments.fees.promotional": "Options promotionnelles",
        "terms.liability.title": "6. Limitation de Responsabilité",
        "terms.liability.description": "Habittatt ne sera pas responsable des dommages indirects, accessoires, spéciaux, consécutifs ou punitifs.",
        "terms.changes.title": "7. Modifications des Conditions",
        "terms.changes.description": "Nous nous réservons le droit de modifier ces conditions à tout moment. Votre utilisation continue de Habittatt indique votre acceptation des modifications.",
        "terms.termination.title": "8. Résiliation",
        "terms.termination.description": "Nous pouvons mettre fin ou suspendre votre accès au service à tout moment, pour quelque raison que ce soit.",
        "terms.disputes.title": "9. Règlement des Litiges",
        "terms.disputes.description": "Tout litige sera résolu conformément aux lois en vigueur.",
        "terms.contact.title": "10. Informations de Contact",
        "terms.contact.description": "Pour toute question concernant ces Conditions, veuillez nous contacter à :",
        "terms.language.title": "11. Langue",
        "terms.language.description": "En cas de divergence entre les versions française et anglaise, la version française prévaudra.",
        "terms.jurisdiction.title": "12. Juridiction",
        "terms.jurisdiction.description": "Ces conditions sont régies par les lois du pays où Habittatt opère."
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fr',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    }
  });

export default i18n;