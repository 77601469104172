import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { login, signup } from '../services/auth';
import { countries } from '../utils/helpers';
import ModalHeader from './ModalHeader';
import Toast from './Toast';

const AuthModal = ({ onClose, onSuccess }) => {
  const [activeTab, setActiveTab] = useState('signin');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [country, setCountry] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [toast, setToast] = useState(null);
  const { t } = useTranslation();

  const showToast = (message, type = 'error') => {
    setToast({ message, type });
  };

  const validateForm = () => {
    if (!email || !password) {
      showToast(t('Please fill in all required fields'));
      return false;
    }

    if (!email.includes('@')) {
      showToast(t('Please enter a valid email address'));
      return false;
    }

    if (password.length < 6) {
      showToast(t('Password must be at least 6 characters long'));
      return false;
    }

    if (activeTab === 'signup') {
      if (!fullName || !country || !phoneNumber) {
        showToast(t('Please fill in all required fields'));
        return false;
      }

      if (phoneNumber.length < 8) {
        showToast(t('Please enter a valid phone number'));
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    try {
      if (activeTab === 'signin') {
        await login(email, password);
        showToast(t('Successfully signed in'), 'success');
        setTimeout(() => {
          onSuccess();
        }, 1000);
      } else {
        const phoneCountryCode = countries[country]?.phoneCountryCode || '';
        const fullPhoneNumber = phoneCountryCode + phoneNumber;
        await signup(fullName, email, password, country, fullPhoneNumber);
        showToast(t('Successfully signed up'), 'success');
        setTimeout(() => {
          onSuccess();
        }, 1000);
      }
    } catch (error) {
      const errorMessage = error.message || 
        (activeTab === 'signin' 
          ? t('Invalid email or password') 
          : t('Error during sign up'));
      showToast(errorMessage);
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <ModalHeader 
          title={activeTab === 'signin' ? t('Sign In') : t('Sign Up')} 
          onClose={onClose} 
        />
        <div className="modal-body">
          <div className="auth-tabs">
            <button
              className={`auth-tab ${activeTab === 'signin' ? 'active' : ''}`}
              onClick={() => setActiveTab('signin')}
            >
              {t('Sign In')}
            </button>
            <button
              className={`auth-tab ${activeTab === 'signup' ? 'active' : ''}`}
              onClick={() => setActiveTab('signup')}
            >
              {t('Sign Up')}
            </button>
          </div>
          
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('Email')}
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('Password')}
              required
            />
            {activeTab === 'signup' && (
              <>
                <input
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder={t('Full Name')}
                  required
                />
                <select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                >
                  <option value="">{t('Select Country')}</option>
                  {Object.keys(countries).map(countryName => (
                    <option key={countryName} value={countryName}>
                      {t(countryName)}
                    </option>
                  ))}
                </select>
                <div className="phone-input">
                  <input
                    type="text"
                    value={countries[country]?.phoneCountryCode || ''}
                    readOnly
                    className="country-code"
                  />
                  <input
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, ''))}
                    placeholder={t('Phone Number')}
                    required
                  />
                </div>
              </>
            )}
            <button type="submit" className="submit-button">
              {activeTab === 'signin' ? t('Sign In') : t('Sign Up')}
            </button>
          </form>

          {toast && (
            <Toast
              message={toast.message}
              type={toast.type}
              onClose={() => setToast(null)}
              duration={5000}
              showOverlay={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthModal;