import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthModal from './AuthModal';
import PostModal from './PostModal';
import EditProfileModal from './EditProfileModal';
import { logout } from '../services/auth';
import { User, Home } from 'lucide-react';


const Header = ({ changeLanguage, isUserSignedIn, setIsUserSignedIn }) => {
  const { t, i18n } = useTranslation();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);


  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'fr' : 'en';
    changeLanguage(newLang);
  };

  const handlePostClick = () => {
    if (isUserSignedIn) {
      setShowPostModal(true);
    } else {
      setShowAuthModal(true);
    }
  };

  const handleAuthClick = () => {
    if (isUserSignedIn) {
      logout();
      setIsUserSignedIn(false);
    } else {
      setShowAuthModal(true);
    }
  };

  const handleEditProfileClick = () => {
    setShowEditProfileModal(true);
  };

  return (
    <header className="header">
      <div className="header-content">
        <div className="logo-container">
          <Link to="/" className="logo">
            <span className="desktop-logo">Habittatt</span>
            <span className="mobile-logo">H</span>
          </Link>
        </div>
        <div className="header-buttons">
          <div className="button-group">
            <button onClick={handlePostClick} className="post-button" title="Post">
              <Home size={16} />
              <span className="button-icon">+</span>
              <span className="button-text">Post</span>
            </button>
            {isUserSignedIn && (
              <button onClick={handleEditProfileClick} className="edit-button" title="Edit Profile">
                <User size={16} />
                <span className="button-text">{t('Account')}</span>
              </button>
            )}
          </div>
          <div className="button-group">
            <button 
              onClick={handleAuthClick} 
              className={`auth-button ${isUserSignedIn ? 'signed-in' : ''}`}
            >
              {isUserSignedIn ? t('Sign Out') : t('Sign In')}
            </button>
            <button onClick={toggleLanguage} className="language-toggle">
              {i18n.language === 'en' ? 'FR' : 'EN'}
            </button>
          </div>
        </div>
      </div>
      {showAuthModal && (
        <AuthModal 
          onClose={() => setShowAuthModal(false)} 
          onSuccess={() => {
            setIsUserSignedIn(true);
            setShowAuthModal(false);
          }} 
        />
      )}
      {showPostModal && <PostModal onClose={() => setShowPostModal(false)} />}
      {showEditProfileModal && (
        <EditProfileModal
          onClose={() => setShowEditProfileModal(false)}
          onSuccess={() => setShowEditProfileModal(false)}
        />
      )}
    </header>
  );
};

export default Header;